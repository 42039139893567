<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      P
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name" class />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      T
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name" class />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      A
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name" class />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      E
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name" class />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      O
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name" class />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </section>

        <section class="centered">
          <a class="link_bt bt_save">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UM_AU"
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 20%;
}
.colspan_flex_adj {
  display: flex;
  justify-content: space-around;
}
.colspan_flex_adj .label_and_element_wrapper {
  width: 100%;
}
</style>